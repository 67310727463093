@import '../../components/variables.scss';

$loginWidth: 100%;
$ssoButtonWidth: 450px;

.centerColumn {
  width: $ssoButtonWidth;
}

.loginPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 10vh;
  max-width: 70vw;
  height: 100%;
  color: $c-primary;

  .heading {
    font-size: $fs-title;
  }

  .banner {
    flex-direction: column;
    display: flex;
    gap: 1vh;
    align-items: center;
    text-align: center;
  }

  .info {
    font-size: 12px;
    text-align: justify;
    box-sizing: border-box;

    a {
      text-decoration: none;
      color: #57abff;
    }
  }

  .separator {
    border-bottom: 1px solid #5b5b5b;
    width: $loginWidth;
  }
}

.ssoProviderButton {
  background-color: #2b3245;
  color: #bfbfbf;
  border-radius: 5px;
  border: solid 1px #2b3245;
  padding: 10px 10px 10px 10px;
  // border: solid 1px #ED05E6;
  width: $ssoButtonWidth;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0 10px 0px 0;
    height: 24px;
  }

  .logo {
    margin: 0px 0px 0px 6px;
  }

  &:hover {
    background: #3c445c;
  }
}

.termsAndConditions {
  width: $ssoButtonWidth;
}
