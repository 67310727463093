.company-card {
  --text-color: #4b5563;
  --header-color: #2c55c8;
  --background: linear-gradient(to right bottom, rgb(249, 250, 251), rgb(243, 244, 246));
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;

  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

  background: linear-gradient(to right bottom, rgb(249, 250, 251), rgb(243, 244, 246));
  padding: 2em 2em;
  margin: 2em 0;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  font-size: 15px;

  color: var(--text-color);

  .capitalize,
  .person-name,
  .job-title {
    text-transform: capitalize;
  }

  .list-item {
    display: flex;
    gap: 0.75em;

    svg {
      margin-top: 3px;
      width: 18px;
      height: 18px;
      color: var(--gray-400);
      display: flex;
      flex-shrink: 0;
    }
  }

  h1 {
    margin: 0;
    color: var(--header-color);
    flex: 1;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  p {
    margin: 0;
  }

  &__header {
    color: var(--header-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;

    a {
      color: var(--header-color);
      border: solid 1px var(--header-color);
      border-radius: 50vh;
      padding: 0.25em;
    }
  }

  &__content {
    // border-radius: 0.75em;

    h2 {
      margin: 0;
      padding-bottom: 1em;
      color: var(--header-color);
    }
  }

  &__info {
    position: relative;
    z-index: 1;
    background: white;
    border-bottom-left-radius: 0.75em;
    border-bottom-right-radius: 0.75em;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;

    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-template-areas:
    //   'person company'
    //   'tPoints news';
    gap: 4em;
    padding: 2em;

    display: flex;
    flex-direction: column;
    gap: 3em;

    article {
      display: flex;
      gap: 3em;
      flex-wrap: wrap;

      & > div {
        min-width: 350px;
      }
    }

    .section {
      &__company {
        grid-area: company;
        flex: 1;
      }

      &__person {
        grid-area: person;
        flex: 1;
      }

      &__news {
        grid-area: news;
        flex: 1;
      }

      &__talking-points {
        grid-area: tPoints;
        flex: 1;
      }
    }
  }
}
