.profile {
  &__box {
    // border: solid 1px red;
    .btn-link {
      font-size: 0.6em;
      display: inline-flex;
      align-items: center;
      gap: 0.25em;
      margin: 0 0.5em;
      background: none;
      border: none;
      color: #228be6;

      &:disabled {
        color: var(--gray-300);
      }
    }

    &__left {
      display: flex;
      gap: 2em;
      align-items: center;
    }

    .avatar {
      img {
        border-radius: 50vh;
        width: 150px;
      }
    }

    .title {
      font-size: 1.5em;
    }

    .roles {
      display: flex;
      align-items: center;
      gap: 1em;
      margin-top: 1em;
    }

    .badges {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;
    max-width: 500px;

    .actions {
      display: flex;
      gap: 1em;
    }
  }
}
