.layout {
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  min-height: 100vh;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 50px 1fr 50px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'header header'
    'nav content'
    'nav footer';

  .container {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 1em;
  }

  &__header {
    grid-area: header;
    border-bottom: solid 1px #dee2e6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1em;

    position: sticky;
    top: 0;
    z-index: 10;
    background: white;

    h1 {
      display: flex;
      align-items: center;
      gap: 0.5em;
      color: var(--gray-400);
    }
  }

  &__nav {
    grid-area: nav;

    display: flex;
    flex-direction: column;
    border-right: solid 1px #dee2e6;

    position: sticky;
    max-height: calc(100vh - 50px);
    top: 50px;

    padding-bottom: 1em;

    .main-nav {
      flex: 1;
      padding: 0.5em;
      border-bottom: solid 1px #dee2e6;
    }

    .user-nav {
      padding: 0.5em;
      border-bottom: solid 1px #dee2e6;
    }

    .admin-nav {
      border-bottom: solid 1px #dee2e6;
      padding: 0.5em;
    }
  }

  &__content {
    grid-area: content;
  }

  &__footer {
    background: #dee2e6;
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    font-size: 0.8em;
  }

  svg {
    display: flex;
  }

  textarea {
    width: 100%;
    border-radius: 0.5em;
    border: solid 1px var(--blue-300);
    padding: 1em 1.5em;
    margin-bottom: 1em;
  }
}
