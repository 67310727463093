@import '../../../components/variables.scss';

$color_1: #666;
$color_2: #aaa;
$background-color_1: #bbb;
$background-color_2: #999;
$border-right-color_1: transparent;
$border-right-color_2: $c-accents;
$border-left-color_1: transparent;

$c-border: $c-subtle;

.rc-table {
  position: relative;
  box-sizing: border-box;
  color: $color_1;
  font-size: 10px;
  line-height: 1.5;

  table {
    width: 100%;
    border-spacing: 0px;
  }

  th {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    padding: 16px 8px;
    white-space: normal;
    word-break: break-word;
    border: 1px solid $c-border;
    border-top: 0;
    border-left: 0;
    transition: box-shadow 0.3s;
  }

  td {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    padding: 16px 8px;
    white-space: normal;
    word-break: break-word;
    border: 1px solid $c-border;
    border-top: 0;
    border-left: 0;
    transition: box-shadow 0.3s;
  }

  thead {
    th {
      text-align: center;
      background: #f7f7f7;
    }

    .rc-table-cell-scrollbar {
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 1px;
        background: #f7f7f7;
        content: '';
      }
    }
  }

  tbody {
    tr {
      td {
        background: #fff;
      }

      th {
        background: #f7f7f7;
      }
    }
  }

  tfoot {
    td {
      background: #fff;
    }
  }
}

.rc-table-rtl {
  direction: rtl;

  .rc-table-cell-fix-right {
    &:last-child {
      border-right-color: $border-right-color_2;
    }
  }

  .rc-table-cell-fix-left {
    &:last-child {
      border-left-color: $border-left-color_1;
    }
  }

  .rc-table-cell-fix-left-first {
    box-shadow: 1px 0 0 $c-border;
  }

  .rc-table-cell-fix-right-first {
    box-shadow: none;
  }

  .rc-table-cell-fix-right-last {
    box-shadow: none;
  }
}

.rc-table-rtl.rc-table {
  th {
    border-right: 0;
    border-left: 1px solid $c-border;
  }

  td {
    border-right: 0;
    border-left: 1px solid $c-border;
  }

  thead {
    .rc-table-cell-scrollbar {
      &::after {
        right: -1px;
        left: auto;
      }
    }
  }
}

.rc-table-cell-fix-left {
  z-index: 1;
}

.rc-table-cell-fix-right {
  z-index: 1;

  &:last-child {
    &:not(.rc-table-cell-fix-sticky) {
      border-right-color: $border-right-color_1;
    }
  }
}

.rc-table-cell-fix-left-first {
  &::after {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: -1px;
    width: 20px;
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
}

.rc-table-cell-fix-left-last {
  &::after {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: -1px;
    width: 20px;
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
}

.rc-table-cell-fix-left-all {
  &::after {
    display: none;
  }
}

.rc-table-cell-fix-right-first {
  box-shadow: -1px 0 0 $c-border;

  &::after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -1px;
    width: 20px;
    transform: translateX(-100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
}

.rc-table-cell-fix-right-last {
  box-shadow: -1px 0 0 $c-border;

  &::after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -1px;
    width: 20px;
    transform: translateX(-100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
}

.rc-table-cell.rc-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-first {
  overflow: visible;

  .rc-table-cell-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-last {
  overflow: visible;

  .rc-table-cell-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-first {
  .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-last {
    overflow: visible;

    .rc-table-cell-content {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.rc-table-cell.rc-table-cell-row-hover {
  background: $c-results-bg;
}

.rc-table-ping-left {
  .rc-table-cell-fix-left-first {
    &::after {
      box-shadow: inset 10px 0 8px -8px green;
    }
  }

  .rc-table-cell-fix-left-last {
    &::after {
      box-shadow: inset 10px 0 8px -8px green;
    }
  }
}

.rc-table-ping-right {
  .rc-table-cell-fix-right-first {
    &::after {
      box-shadow: inset -10px 0 8px -8px green;
    }
  }

  .rc-table-cell-fix-right-last {
    &::after {
      box-shadow: inset -10px 0 8px -8px green;
    }
  }
}

.rc-table-expand-icon-col {
  width: 60px;
}

.rc-table-row-expand-icon-cell {
  text-align: center;
}

.rc-table-header {
  border: 1px solid $c-border;
  border-right: 0;
  border-bottom: 0;
}

.rc-table-placeholder {
  text-align: center;
}

.rc-table-content {
  border: 1px solid $c-border;
  border-right: 0;
  border-bottom: 0;
  border-radius: 5px 0 0 0;
}

.rc-table-body {
  border: 1px solid $c-border;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
}

.rc-table-fixed-column {
  .rc-table-body {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      border-right: 1px solid $c-border;
      content: '';
    }
  }
}

.rc-table-expanded-row {
  .rc-table-cell {
    box-shadow: inset 0 8px 8px -8px green;
  }
}

.rc-table-expanded-row-fixed {
  box-sizing: border-box;
  margin: -16px -8px;
  margin-right: -10px;
  padding: 16px 8px;

  &::after {
    position: absolute;
    top: 0;
    right: 1px;
    bottom: 0;
    width: 0;
    border-right: 1px solid $c-border;
    content: '';
  }
}

.rc-table-row-expand-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: $color_2;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid currentColor;
  cursor: pointer;
}

.rc-table-row-expand-icon.rc-table-row-expanded {
  &::after {
    content: '-';
  }
}

.rc-table-row-expand-icon.rc-table-row-collapsed {
  &::after {
    content: '+';
  }
}

.rc-table-row-expand-icon.rc-table-row-spaced {
  visibility: hidden;
}

.rc-table-title {
  padding: 16px 8px;
  border: 1px solid $c-border;
  border-bottom: 0;
}

.rc-table-footer {
  padding: 16px 8px;
  border: 1px solid $c-border;
  border-top: 0;
}

.rc-table-summary {
  border-top: 1px solid $c-border;
  border-left: 1px solid $c-border;
}

.rc-table-sticky-holder {
  position: sticky;
  z-index: 2;
}

.rc-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  border-top: 1px solid #f3f3f3;
  opacity: 0.6;
  transition: transform 0.1s ease-in 0s;

  &:hover {
    transform: scaleY(1.2);
    transform-origin: center bottom;
  }
}

.rc-table-sticky-scroll-bar {
  height: 8px;
  background-color: $background-color_1;
  border-radius: 4px;

  &:hover {
    background-color: $background-color_2;
  }
}

.rc-table-sticky-scroll-bar-active {
  background-color: $background-color_2;
}