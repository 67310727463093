.nav {
  /* border: solid 1px red; */
  position: relative;
  z-index: 0;
}

ul.companies {
  display: flex;
  flex-direction: row;
  /* border: solid 1px #ccc; */
  /* gap: 2px; */
}

.tabs {
  border-bottom: 0;
  padding: 1em 0.5em;
  border-top-left-radius: 0.75em;
  border-top-right-radius: 0.75em;
}

.companyName {
  display: flex;
  align-items: center;
  gap: 0.75em;
  /* border: solid 1px #ccc; */
  padding: 0.5em;
  text-transform: capitalize;
  font-weight: 500;
}

.activeCompany {
  background: white;
  box-shadow: 0px 0px 20px 0px #ddd;
}

.activeCompany.noCompany {
  background: none;
  box-shadow: none;
}

button {
  display: inline-flex;
  align-items: center;
  gap: 0.75em;
  margin: 0.5em;
  padding: 0.5em 1em;
  background: none;
  background: var(--gray-200);
  border-radius: 0.25em;
  /* border: solid 1px #ccc; */
  border: none;
  text-transform: capitalize;
}

.activeCompany button {
  background: var(--blue-100);
}

button.active {
  background: var(--header-color);
  color: white;
  border-color: transparent;
}

.jobTitle {
  font-size: 0.6em;
}
