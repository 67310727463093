.groupWBorder {
  /* border:1px solid burlywood; */
  border-radius: 5px;
  padding: 5px;
  background-color: "#f2f3f5"
}

.cardNode {}

.selectedNode {
  outline: 4px solid rgb(255, 97, 123) !important;
  outline-offset: 7px;
}

.terminalNode {
  .mantine-Card-root {
    --_paper-border: 4px double var(--blue-700) !important;
    /* outline-offset: 5px; */
  }
}

.errorNode {
  .mantine-Card-root {
    --_paper-border: 4px solid var(--orange-400) !important;
    /* outline-offset: 5px; */
  }
}

.breakLongLines {
  overflow-wrap: break-word;
  overflow-wrap: anywhere;
}

.codeBlock {
  white-space: pre-wrap;
  word-break: break-word;
  overflow-y: scroll;
}