div.billing {
  font-family: var(--herbie-fontfamily);

  .PlanCard {
    margin: 10px 10px;
    outline: 1px solid rgb(176, 176, 176);
    border-radius: 6px;
    width: 290px;
    // height: 250px;

    &:hover {
      outline: 1px solid black;
    }
  }

  .Premium {
    outline: 1px solid #e89300;
  }
}

.trackLabel {
  padding: 3px 20px;
}