.conversations {
  padding-bottom: 3em;

  .sh-quoted-content {
    display: none;
  }

  .message {
    font-size: 0.7em;

    .markdown {
      color: var(--indigo-800);
    }
  }

  .gmail_quote {
    display: none;
  }
}
