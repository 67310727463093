.brief {
  // border: solid 1px red;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;

  &__header {
    display: flex;
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    gap: 2em;
    font-size: 0.8em;

    &__left {
      flex: 1;
    }

    &__id {
      color: var(--blue-400);
      font-size: 0.9em;
    }
  }

  &__urls {
    display: flex;
    flex-direction: column;
    gap: 1em;
    font-size: 0.8em;
  }

  .status {
    font-weight: 500;
    &.--confirmed {
      color: var(--green-500);
    }
  }

  pre {
    padding: 1em;
    white-space: pre-wrap;
    border-radius: 0.5em;
    color: black;
    background: var(--gray-100);
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  &__workflow {
    &__content {
      display: flex;
      gap: 1em;

      aside {
        flex: 1;
      }
    }
  }

  .graph {
    border: solid 1px var(--gray-200);
    border-top: none;
    // border-radius: 0.5em;
    width: 100%;
    height: 80vh;

    flex: 2;
  }

  .node {
    border: solid 1px grey;
    padding: 0.5em;
  }
}
