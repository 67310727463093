.table {
  /* border: solid 1px red; */
  border-collapse: collapse;

  width: 100%;
  font-size: 0.85em;

  margin-bottom: 2em;

  /* border: solid 1px #eee; */
  color: var(--gray-600);
}

.thead {
  background: #f5f5f5;
}

.th {
  text-align: left;
  padding: 1em 1em;
}

.td {
  border-top: solid 1px #eee;
  padding: 0.75em 1em;
  /* border: solid 1px #ccc; */

  a {
    color: #40c057;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 1em;
  }

  :global(a.blue) {
    color: var(--blue-500);
  }
}

.tr:nth-child(even) {
  background: #fafafa;
}

.tr:has(a:hover) {
  /* background: #f6f6f6; */
  background: #7950f21a;
  transition: 0.2s;
}

.sticky {
  position: sticky;
  z-index: 5;
  top: 49px;
}
