.item {
  background: rgb(249, 250, 251);
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;

  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: 0.9em;
}

.title {
  color: var(--header-color);
  font-size: 1rem;
  font-weight: 500;
}
