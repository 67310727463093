.edit-user {
  // border: solid 1px red;
  font-size: 0.9em;
  padding: 2em 0;

  &.container {
    max-width: 1180px;
    margin: 0 auto;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__profile {
    border: solid 1px #dee2e6;
    box-shadow: 0 0 10px #dee2e6;
    overflow: hidden;
    border-radius: 0.5em;
    margin: 0 auto;

    display: flex;
    transition: 1s;
  }

  &__preview {
    width: 400px;
    flex-basis: 400px;
    // flex: 1;
  }

  &__form {
    flex: 1;
    // background: #eee;
    padding: 1em;
    width: 500px;

    display: flex;
    flex-direction: column;
    gap: 1em;

    &__actions {
      display: flex;
      justify-content: end;
      gap: 1em;
    }
  }

  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &__details {
    padding: 0.75em 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #dee2e6 solid 1px;
  }

  &__footer {
    padding: 0.75em 1.5em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    color: #343a40;

    &__time {
      color: #868e96;
      display: flex;
      justify-content: space-between;
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 1em;

      .--badges {
        display: flex;
        align-items: center;
        gap: 0.75em;
      }
    }
  }

  &__membership {
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 0.5em;
    // margin: 2em 0;
  }

  &__transactions {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 3em 0;

    h3 {
      text-align: center;
    }
  }
}
