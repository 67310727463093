.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.item {
  --text-color: #4b5563;
  --header-color: #2c55c8;
  --background: linear-gradient(to right bottom, rgb(249, 250, 251), rgb(243, 244, 246));

  background: var(--background);
  padding: 1em 2em;
  border-radius: 1em;
}

.item h4,
.item h3 {
  margin: 0;
  color: var(--header-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item p {
  margin: 0;
  font-size: 0.9em;
  color: var(--text-color);
}

.details {
  margin: 1em 0;
  display: flex;
  gap: 3em;
}

.people,
.companies {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.company,
.person {
  --icon-color: var(--blue-700);
  display: flex;
  gap: 1em;
  align-items: center;
}

.company svg,
.person svg {
  color: var(--icon-color);
}

.title {
  text-transform: capitalize;
  font-weight: 500;
}

.pending {
  --icon-color: var(--orange-300);
}

.failed {
  --icon-color: var(--red-600);
}

.ready {
  --icon-color: var(--green-600);
}

.icon {
  border: solid 1px var(--icon-color);
  border-radius: 50vh;
}
