.user-card {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1em;

  padding: 1em 0.5em;
  cursor: pointer;

  svg {
    width: 18px;
    transition: 0.5s;
  }

  &.login {
    a {
      display: flex;
      align-items: center;
      gap: 0.75em;
      text-decoration: none;
      color: var(--gray-600);
      padding: 0.5em;

      svg {
        width: 18px;
      }
    }
  }

  &.show {
    background: var(--gray-100);

    svg {
      rotate: 90deg;
    }
  }

  img {
    display: flex;
    height: 36px;
    border-radius: 50vh;
  }

  .email {
    font-size: 0.7em;
  }

  &__sub-menu {
    list-style: none;
    margin: 0;

    li {
      // border: solid 1px red;
      padding: 0.5em;
    }

    a {
      display: flex;
      align-items: center;
      gap: 0.5em;
      text-decoration: none;
      color: var(--gray-600);
      font-size: 0.9em;
      svg {
        width: 18px;
      }
    }
  }
}
