@import '../variables.scss';

.MainLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  max-height: 48px;

  .logoImg {
    height: 48px;

    img {
      height: 100%;
    }
  }
}