@import '../../components/variables.scss';

.linkStyle {
  text-decoration: none;
  color: var(--mantine-color-gray-5);
}

.tabStyle {
  --_tab-border-color: var(--mantine-color-pink-3) !important;
  --_tab-border-bottom-color: var(--mantine-color-pink-0) !important;
  border-left: 1px solid var(--mantine-color-pink-3) !important;
  border-right: 1px solid var(--mantine-color-pink-3) !important;
  border-bottom: 1px solid var(--mantine-color-pink-3) !important;
}


.broadnTab {
  --_tab-border-color: var(--mantine-color-pink-3) !important;
  --_tab-border-bottom-color: var(--mantine-color-pink-0) !important;
}

// .mantine-Tabs-tab {
//   &[data-active='true'] {
//     --_tab-border-bottom-color: var(--mantine-color-pink-1) !important;
//   }
// }


.scrollAreaInDefaultTab {
  padding: 8px;
  border-left: 1px solid var(--mantine-color-gray-3) !important;
  border-right: 1px solid var(--mantine-color-gray-3) !important;
  border-bottom: 1px solid var(--mantine-color-gray-3) !important;

  .mantine-Tabs-tab {
    &[data-active='true'] {
      --_tab-border-bottom-color: var(--mantine-color-gray-3) !important;
    }
  }
}